import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import {
  getStudentBlogArticles,
  getStudentBlogPopularArticles,
} from '../../api/blogApi'
import { BlogLandingPageContainerCompositionRoot } from '../../components/pages/BlogLandingPage'
import { buildViewModel } from '../../components/pages/BlogLandingPage/viewModelBuilder/viewModelBuilder'
import {
  withCountryServer,
  studentBlogsLinkFromCountryCode,
  countryStateFromQuery,
} from '../../contexts/countryContext'

const studentBlogsGetServerSideProps = async ({ query }) => {
  const { country } = countryStateFromQuery(query)

  const viewModel = await buildViewModel({
    relativeUrl: studentBlogsLinkFromCountryCode(country),
    queryParams: query || {},
    getArticles: getStudentBlogArticles,
    getPopularArticles: getStudentBlogPopularArticles,
  })

  return { props: { viewModel } }
}

export const getServerSideProps = withCountryServer(
  studentBlogsGetServerSideProps
)

const StudentBlog = ({ viewModel }) => {
  return (
    <BlogLandingPageContainerCompositionRoot
      viewModel={viewModel}
      history={useRouter()}
    />
  )
}

StudentBlog.propTypes = {
  viewModel: PropTypes.object.isRequired,
}

export default StudentBlog
